<template>
  <div
    id="app"
    class="min-h-screen"
    :class="this.$route.meta.transition == 'left' ? 'bg-white' : 'bg-darkblue'"
  >
    <transition
      enter-active-class="transform-gpu transition-all duration-300 ease-in-out"
      leave-active-class="transform-gpu transition-all duration-300 ease-in-out"
      :enter-class="
        this.$route.meta.transition == 'left'
          ? 'translate-x-96vw'
          : '-translate-x-96vw'
      "
      enter-to-class="translate-x-0"
      leave-class="translate-x-0"
      :leave-to-class="
        this.$route.meta.transition == 'left'
          ? 'translate-x-96vw'
          : '-translate-x-96vw'
      "
    >
      <!-- <cookie-consent @accept="loadAdsense"></cookie-consent> -->
      <router-view class="min-h-screen font-primary" :key="$route.path" />
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  watch: {
    $route() {},
  },
  mounted() {
    // Check if consent was already given and load AdSense if so
    // if (this.$cookieConsent.hasConsented()) {
      // this.loadAdsense();
    // }
  },
  methods: {
    // loadAdsense() {
    //   // Load AdSense script
    //   const script = document.createElement('script');
    //   script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    //   script.async = true;
    //   document.head.appendChild(script);

    //   setTimeout(() => {
    //     if (window && window.adsbygoogle !== undefined) {
    //       // ts-ignore
    //       (adsbygoogle = window.adsbygoogle || []).push({});
    //     }
    //   }, 1000);
    // },
  },
};
</script>

<style lang="scss">
$primary: #0e131f;

@import "@/assets/scss/tailwind.scss";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: $primary;
}
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: $primary;

    &.router-link-exact-active {
      color: $primary;
    }
  }
}
/* width */
::-webkit-scrollbar {
  width: 8px;
}
/* Track */
::-webkit-scrollbar-track {
  background: $primary;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #171c36;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #171c36;
}
::-webkit-scrollbar-corner {
  background: $primary;
}
</style>
