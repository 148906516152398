<template>
  <main
    class="
      transform-gpu
      transition-all
      duration-1000
      ease-out
      items-stretch
      justify-center
      h-screen
      w-full
      max-w-100vw
      overflow-hidden
      flex flex-col flex-1
      bg-darkblue
      text-white
    "
  >
    <div
      class="
        flex flex-col
        items-stretch
        text-left
        pr-8
        pl-12
        sm:pl-12
        md:pl-24
        xl:pl-32
        flex-1
        relative
      "
    >
      <transition
        enter-active-class="transform transition-all duration-500 ease-in"
        leave-active-class="transform transition-all duration-500 ease-in"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <HeaderComponent
          v-if="loadHeading"
          :darkTheme="false"
        ></HeaderComponent>
      </transition>

      <div
        class="
          xl:flex
          xl:items-start
          xl:justify-between
          max-h-90vh
          pt-14
          md:pt-24
          overflow-auto
        "
      >
        <div class="xl:max-w-full xl:pr-650px">
          <transition
            enter-active-class="tranform transition-all duration-500 ease-in-out"
            leave-active-class="tranform transition-all duration-500 ease-in-out"
            enter-class="-ml-14 opacity-0"
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-to-class="-ml-14 opacity-0"
          >
            <div
              v-if="loadContent"
              class="flex flex-wrap items-center justify-start"
            >
              <img
                src="img/kings-icon.png"
                alt="Kings App Icon"
                width="50px"
                height="50px"
                class="
                  rounded-md
                  mr-3
                  md:mr-4
                  mb-0
                  lg:w-20
                  lg:h-20
                  lg:flex-shrink-0
                "
              />
              <h1
                class="
                  font-concurso
                  uppercase
                  tracking-tighter
                  text-5xl
                  md:text-8xl
                "
              >
                Kings
              </h1>
              <small
                class="
                  block
                  uppercase
                  w-full
                  md:inline-flex
                  md:leading-1
                  md:ml-4
                  md:mb-1
                  md:self-end
                  text-xl
                  lg:text-2xl
                  md:w-auto
                "
                >Drinking Game</small
              >
            </div>
          </transition>
          <transition
            enter-active-class="tranform transition-all duration-500 ease-in-out"
            leave-active-class="tranform transition-all duration-500 ease-in-out"
            enter-class="-ml-14 opacity-0"
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-to-class="-ml-14 opacity-0"
          >
            <div v-if="loadContent" class="text-sm md:text-md mt-6">
              <p class="mb-3">
                Kings is a drinking game that uses playing cards. In our case
                its virtual cards.
              </p>
              <p class="mb-3">
                The player must drink and pour drinks based on the cards they
                draw. Each card has a rule. These rules are based on the game
                mode selected or a custom game mode created by the user.
              </p>
              <p class="mb-3">Create and save custom games on your device.</p>
              <p class="mb-3">
                If you clear your site data you will lose the custom games you
                have created. We do not store any of your data on our servers.
              </p>

              <br />
              <p class="mt-2 mb-3">
                Enjoy at your own risk <i class="icon-wink"></i>
              </p>
              <a
                href="https://play.google.com/store/apps/details?id=com.cookies4later.kings.twa"
                title="download on google play store"
                class="
                  border border-white
                  rounded-md
                  inline-flex
                  font-normal
                  mt-3
                  mb-1
                  justify-start
                  items-center
                  p-3
                  text-base
                  hover:border-yellow-600
                  flex-shrink-1
                  hover:text-yellow-600
                "
              >
                <i class="icon-googleplay mr-2"></i> Download on Google Play
              </a>

              <div class="mt-2">
                <router-link
                  to="/terms"
                  class="
                    text-white
                    cursor-pointer
                    border-b border-white
                    text-xs
                    pt-2
                    font-primary font-medium
                    hover:text-gray-400
                    hover:border-gray-400
                  "
                  ><span
                    >Information on our privacy policy and terms</span
                  ></router-link
                >
              </div>
            </div>
          </transition>
        </div>

        <div class="w-full xl:w-0">
          <transition
            enter-active-class="tranform transition-all duration-1000 ease-in"
            leave-active-class="tranform transition-all duration-1000 ease-in"
            enter-class="opacity-0"
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
          >
            <div v-if="loadImage">
              <img
                class="md:-mt-3 xl:hidden"
                src="img/kings-marketing-md.png"
                alt="Kings App Images"
                width="100%"
                height="auto"
              />
              <img
                class="
                  hidden
                  xl:block
                  absolute
                  right-4
                  top-4
                  xl:w-650px
                  xl:right-12
                  2xl:right-24
                  h-auto
                "
                src="img/kings-marketing-lg.png"
                alt="Kings App Images"
                width="650px"
                height="auto"
              />
            </div>
          </transition>
        </div>
      </div>

      <router-link
        to="/"
        class="
          fixed
          left-0
          top-0
          h-screen
          w-8
          md:w-12
          bg-darkerblue
          text-white
          hover:bg-black
          transition transition-bg
          flex
          items-center
          justify-center
        "
      >
        <div class="relative h-screen w-4vw flex items-center justify-center">
          <span
            class="
              absolute
              uppercase
              w-100vh
              h-6
              flex
              items-center
              justify-center
              transform
              rotate-90
              tracking-wide
            "
            >Home<i class="icon-arrow"></i
          ></span></div
      ></router-link>
    </div>
  </main>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: "Kings",
  components: {
    HeaderComponent,
  },
  data() {
    return {
      loadContent: Boolean,
      loadHeading: Boolean,
      loadImage: Boolean,
    };
  },
  mounted() {
    this.loadContent = false;
    this.loadHeading = false;
    this.loadImage = false;
    setTimeout(() => {
      this.loadContent = true;
    }, 800);
    setTimeout(() => {
      this.loadHeading = true;
      this.loadImage = true;
    }, 1000);
  },
  beforeDestroy() {
    this.loadContent = false;
    this.loadHeading = false;
    this.loadImage = false;
  },
};
</script>
