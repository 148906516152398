import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Kings from '../views/Kings.vue'
import GamingArt from '../views/GamingArt.vue'
import Terms from '../views/Terms.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    meta: { transition: 'left' },
  },
  {
    path: '/kings',
    name: 'kings',
    meta: { transition: 'right' },
    component: Kings,
  },
  {
    path: '/gaming-art',
    name: 'Gaming Art',
    meta: { transition: 'right' },
    component: GamingArt,
  },
  {
    path: '/terms',
    name: 'terms',
    meta: { transition: 'none' },
    component: Terms,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
