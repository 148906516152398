<template>
  <nav
    class="
      absolute
      top-0
      mb-8
      pt-2
      w-full
      h-12
      flex
      items-center
      justify-start
      z-1
      text-xs
      md:text-sm
      pr-32
      flex-wrap
      gap-2
    "
    :class="[darkTheme ? 'bg-white ' : 'bg-darkblue ']"
  >
    <router-link
      to="/"
      class="uppercase cursor-pointer hover:text-yellow-600"
      :class="[
        [darkTheme ? 'text-darkblue ' : 'text-white '],
        { 'text-yellow-600': this.$route.name == 'home' },
      ]"
      ><span>Home</span>
    </router-link>
    |
    <router-link
      to="/kings"
      class="uppercase cursor-pointer hover:text-yellow-600"
      :class="[
        [darkTheme ? 'text-darkblue' : 'text-white'],
        {
          'text-yellow-600': this.$route.name == 'kings',
        },
      ]"
      ><span>Kings</span>
    </router-link>  |
    <router-link
      to="/gaming-art"
      class="uppercase cursor-pointer hover:text-yellow-600"
      :class="[
        [darkTheme ? 'text-darkblue' : 'text-white'],
        {
          'text-yellow-600': this.$route.name == 'gaming-art',
        },
      ]"
      ><span>Gaming Art</span>
    </router-link>  |
    <router-link
      to="/terms"
      class="uppercase cursor-pointer hover:text-yellow-600"
      :class="[
        [darkTheme ? 'text-darkblue' : 'text-white'],
        {
          'text-yellow-600': this.$route.name == 'terms',
        },
      ]"
      ><span>Terms</span>
    </router-link> 
  </nav>
</template>

<script>
export default {
  name: "HeaderComponent",
  props: {
    darkTheme: Boolean,
  },
};
</script>
