var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"absolute top-0 mb-8 pt-2 w-full h-12 flex items-center justify-start z-1 text-xs md:text-sm pr-32 flex-wrap gap-2",class:[_vm.darkTheme ? 'bg-white ' : 'bg-darkblue ']},[_c('router-link',{staticClass:"uppercase cursor-pointer hover:text-yellow-600",class:[
      [_vm.darkTheme ? 'text-darkblue ' : 'text-white '],
      { 'text-yellow-600': this.$route.name == 'home' },
    ],attrs:{"to":"/"}},[_c('span',[_vm._v("Home")])]),_vm._v(" | "),_c('router-link',{staticClass:"uppercase cursor-pointer hover:text-yellow-600",class:[
      [_vm.darkTheme ? 'text-darkblue' : 'text-white'],
      {
        'text-yellow-600': this.$route.name == 'kings',
      },
    ],attrs:{"to":"/kings"}},[_c('span',[_vm._v("Kings")])]),_vm._v(" | "),_c('router-link',{staticClass:"uppercase cursor-pointer hover:text-yellow-600",class:[
      [_vm.darkTheme ? 'text-darkblue' : 'text-white'],
      {
        'text-yellow-600': this.$route.name == 'gaming-art',
      },
    ],attrs:{"to":"/gaming-art"}},[_c('span',[_vm._v("Gaming Art")])]),_vm._v(" | "),_c('router-link',{staticClass:"uppercase cursor-pointer hover:text-yellow-600",class:[
      [_vm.darkTheme ? 'text-darkblue' : 'text-white'],
      {
        'text-yellow-600': this.$route.name == 'terms',
      },
    ],attrs:{"to":"/terms"}},[_c('span',[_vm._v("Terms")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }