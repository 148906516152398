<template>
  <main
    class="
      transform-gpu
      transition-all
      duration-1000
      ease-out
      items-stretch
      justify-center
      min-h-screen
      w-full
      max-w-100vw
      overflow-hidden
      flex flex-col flex-1
      bg-white
      text-darkblue
      overflow-y-auto
    "
  >
    <div
      class="
        flex flex-col
        items-stretch
        text-left
        pr-8
        pl-12
        sm:pl-12
        md:pl-24
        xl:pl-32
        flex-1
        relative
      "
    >
      <transition
        enter-active-class="tranform transition-all duration-500 ease-in"
        leave-active-class="tranform transition-all duration-500 ease-in"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <HeaderComponent v-if="loadHeading" :darkTheme="true"></HeaderComponent>
      </transition>

      <div
        class="
          md:flex
          md:items-start
          md:justify-between
          max-h-90vh
          pt-14
          md:pt-24
          overflow-auto
        "
      >
        <div class="lg:max-w-2/3">
          <transition
            enter-active-class="tranform transition-all duration-500 ease-in-out"
            leave-active-class="tranform transition-all duration-500 ease-in-out"
            enter-class="-ml-14 opacity-0"
            enter-to-class="opacity-100"
            leave-class="opacity-100"
            leave-to-class="-ml-14 opacity-0"
          >
            <div v-if="loadContent" class="text-sm md:text-base mt-6 pb-10">
              <h1 class="text-2xl">Privacy Policy and Terms of use</h1>
              <div class="mt-8">
                <p>
                  Cookies 4 Later (the “Company”) is committed to maintaining
                  robust privacy protections for its users. Our Privacy Policy
                  (“Privacy Policy”) is designed to help you understand how we
                  collect, use and safeguard the information you provide to us
                  and to assist you in making informed decisions when using our
                  Service.
                </p>
                <p>
                  For purposes of this Agreement, “Site” refers to the Company’s
                  website, which can be accessed at
                  <a href="https://cookies4later.com" target="_blank"
                    >cookies4later.com</a
                  >
                  “Service” refers to the Company’s services accessed via the
                  Site. The terms “we,” “us,” and “our” refer to the Company.
                  “You” refers to you, as a user of our Site or our Service. By
                  accessing our Site or our Service, you accept our Privacy
                  Policy and Terms of Use (found here:
                  <a href="https://cookies4later.com/terms" target="_blank"
                    >cookies4later.com/terms</a
                  >), and you consent to our collection, storage, use and
                  disclosure of your Personal Information as described in this
                  Privacy Policy.
                </p>

                <br />
                <h2 class="text-lg mb-4 md:mb-6">I. INFORMATION WE COLLECT</h2>
                <p>We do not collect any user information.</p>
                <p>
                  The Site and the Service are not directed to anyone under the
                  age of 13. The Site does not knowingly collect or solicit
                  information from anyone under the age of 13, or allow anyone
                  under the age of 13 to sign up for the Service. In the event
                  that we learn that we have gathered personal information from
                  anyone under the age of 13 without the consent of a parent or
                  guardian, we will delete that information as soon as possible.
                  If you believe we have collected such information, please
                  contact us at cookies4later.com.
                </p>

                <h2 class="text-lg my-4 md:my-6">
                  II. HOW WE USE AND SHARE INFORMATION
                </h2>
                <p class="bold mb-4 md:mb-6">Personal Information:</p>
                <p>
                  Except as otherwise stated in this Privacy Policy, we do not
                  sell, trade, rent or otherwise share for marketing purposes
                  your Personal Information with third parties without your
                  consent. We do share Personal Information with vendors who are
                  performing services for the Company, such as the servers for
                  our email communications who are provided access to user’s
                  email address for purposes of sending emails from us. Those
                  vendors use your Personal Information only at our direction
                  and in accordance with our Privacy Policy. In general, the
                  Personal Information you provide to us is used to help us
                  communicate with you. For example, we use Personal Information
                  to contact users in response to questions, solicit feedback
                  from users, provide technical support, and inform users about
                  promotional offers.
                </p>
                <br />
                <p class="bold mb-4 md:mb-6">Non-Personal Information:</p>
                <p>
                  In general, we use Non-Personal Information to help us improve
                  the Service and customize the user experience. We also
                  aggregate Non-Personal Information in order to track trends
                  and analyze use patterns on the Site. This Privacy Policy does
                  not limit in any way our use or disclosure of Non-Personal
                  Information and we reserve the right to use and disclose such
                  Non-Personal Information to our partners, advertisers and
                  other third parties at our discretion.
                </p>
                <p>
                  In the event we undergo a business transaction such as a
                  merger, acquisition by another company, or sale of all or a
                  portion of our assets, your Personal Information may be among
                  the assets transferred. You acknowledge and consent that such
                  transfers may occur and are permitted by this Privacy Policy,
                  and that any acquirer of our assets may continue to process
                  your Personal Information as set forth in this Privacy Policy.
                  If our information practices change at any time in the future,
                  we will post the policy changes to the Site so that you may
                  opt out of the new information practices. We suggest that you
                  check the Site periodically if you are concerned about how
                  your information is used.
                </p>
                <br />
                <h2 class="text-lg mb-4 md:mb-6">
                  III. CHANGES TO OUR PRIVACY POLICY
                </h2>
                <p>
                  The Company reserves the right to change this policy and our
                  Terms of Service at any time. We will notify you of
                  significant changes to our Privacy Policy by sending a notice
                  to the primary email address specified in your account or by
                  placing a prominent notice on our site. Significant changes
                  will go into effect 30 days following such notification.
                  Non-material changes or clarifications will take effect
                  immediately. You should periodically check the Site and this
                  privacy page for updates.
                </p>
                <br />
                <h2 class="text-lg mb-4 md:mb-6">IV. CONTACT US</h2>
                <p>
                  If you have any questions regarding this Privacy Policy or the
                  practices of this Site, please contact us by sending an email
                  to
                  <a href="mailto:cookies4later.o.o@gmail.com" target="_blank"
                    >cookies4later.o.o@gmail.com</a
                  >. Last Updated: This Privacy Policy was last updated on 11
                  August 2021.
                </p>
              </div>
            </div>
          </transition>
        </div>
      </div>

      <router-link
        to="/"
        class="
          fixed
          left-0
          top-0
          h-screen
          w-8
          md:w-12
          bg-darkerblue
          text-white
          hover:bg-black
          transition transition-bg
          flex
          items-center
          justify-center
        "
      >
        <div class="relative h-screen w-4vw flex items-center justify-center">
          <span
            class="
              absolute
              uppercase
              w-100vh
              h-6
              flex
              items-center
              justify-center
              transform
              rotate-90
              tracking-wide
            "
            >Home<i class="icon-arrow"></i
          ></span></div
      ></router-link>
    </div>
  </main>
</template>

<script>
import HeaderComponent from "@/components/HeaderComponent.vue";

export default {
  name: "Terms",
  components: {
    HeaderComponent,
  },
  data() {
    return {
      loadContent: Boolean,
      loadHeading: Boolean,
    };
  },
  mounted() {
    this.loadContent = false;
    this.loadHeading = false;
    setTimeout(() => {
      this.loadContent = true;
    }, 1000);
    setTimeout(() => {
      this.loadHeading = true;
    }, 1500);
  },
  beforeDestroy() {
    this.loadContent = false;
    this.loadHeading = false;
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
</style>