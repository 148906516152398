<!-- Lightbox.vue -->
<template>
    <div v-if="visible" class="lightbox-overlay" @click="closeLightBox">
        <div class="lightbox-content" @click.stop>
            <img :src="images[currentIndex].src" :alt="images[currentIndex].alt" />
            <button class="lightbox-close" @click="closeLightBox">✕</button>
            <button v-if="currentIndex > 0" class="lightbox-prev" @click="prevImage">◀</button>
            <button v-if="currentIndex < images.length - 1" class="lightbox-next" @click="nextImage">▶</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "LightBox",
    props: {
        images: {
            type: Array,
            required: true
        },
        initialIndex: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            visible: false,
            currentIndex: this.initialIndex
        };
    },
    methods: {
        openLightBox(index) {
            this.currentIndex = index;
            this.visible = true;
        },
        closeLightBox() {
            this.visible = false;
        },
        prevImage() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
            }
        },
        nextImage() {
            if (this.currentIndex < this.images.length - 1) {
                this.currentIndex++;
            }
        }
    }
};
</script>

<style scoped>
.lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.lightbox-content {
    position: relative;
    max-width: 90%;
    max-height: 90%;
}

.lightbox-content img {
    width: 100%;
    height: auto;
    border-radius: 10px;
}

.lightbox-close,
.lightbox-prev,
.lightbox-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    color: white;
    font-size: 2em;
    cursor: pointer;
}

.lightbox-close {
    top: 10px;
    right: 10px;
    transform: none;
}

.lightbox-prev {
    left: 10px;
}

.lightbox-next {
    right: 10px;
}
</style>